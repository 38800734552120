import { db, auth } from '../config/firebase'
import { collection, onSnapshot, query, where, doc, deleteDoc, getDoc, updateDoc, orderBy, limit } from "firebase/firestore"
import { useState, useEffect, useRef } from 'react';

export const Prompts = () => {

    const [otherPromptsList, setOtherPromptsList] = useState([]);
    const [completePromptsList, setcompletePromptsList] = useState([]);
    const [myPromptsList, setMyPromptsList] = useState([]);
    const [highestVotedPrompts, setHighestVotedPrompts] = useState([]);
    const [itemToDelete, setItemToDelete] = useState();
    const [itemToComplete, setItemToComplete] = useState();
    const [itemToEdit, setItemToEdit] = useState();
    const [edditedPromptContent, setEdditedPromtContent] = useState();

    const oldPromptContent = useRef(null);   


    const deletePrompt = async (id) => {
        const promptDoc = doc(db, "prompts", id);
        await deleteDoc(promptDoc);
        setItemToDelete();
    }

    const editPrompt = async (id) => {
        const dateOptions = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'};
        const docRef = doc(db, "prompts", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const content = docSnap.data().content
            setItemToEdit({
                id: id, 
                content: content,
                timestamp: docSnap.data().timestamp.toDate().toLocaleTimeString("en-US", dateOptions),
                likes: docSnap.data().likes,
                edited: docSnap.data().edited
            });
        }
    }

    const confirmEditPrompt = async (id) => {
        const docRef = doc(db, "prompts", id);
        const docSnap = await getDoc(docRef);
        const content = docSnap.data().content;

        if(content != edditedPromptContent && edditedPromptContent != ""){
            await updateDoc(doc(db, "prompts", id), {
                content: edditedPromptContent,
                edited: true
            });
        }
        
        setEdditedPromtContent("");
        setItemToEdit("");
    }

    const closeTopVotedPopup = async (id) => {
    
        await updateDoc(doc(db, "prompts", id), {
            hideTopVotedPopup: true
        });
        
        setItemToEdit("");
    }

    const likePrompt = async (id)=> {
        let uid = auth?.currentUser?.uid;

        let likesArray;
        for (let i = 0; i < otherPromptsList.length; i++) {
            if(otherPromptsList[i].id == id){
                likesArray = otherPromptsList[i].likes;
                break
            }
        }

        if(likesArray == undefined){
            likesArray = [];
        }

        if(!likesArray.includes(uid)){
            likesArray.push(uid);
            await updateDoc(doc(db, "prompts", id), {
                likes : likesArray
            });
        }
    }

    const completeItem = async (id) => {
        await updateDoc(doc(db, "prompts", id), {
            complete: true
        });

        setItemToComplete();
    }

    const unlikePrompt = async (id)=> {
        let uid = auth?.currentUser?.uid;

        let likesArray;
        for (let i = 0; i < otherPromptsList.length; i++) {
            if(otherPromptsList[i].id == id){
                likesArray = otherPromptsList[i].likes;
                break
            }
        }

        likesArray = likesArray.filter(item => item !== uid)
        await updateDoc(doc(db, "prompts", id), {
            likes : likesArray
        });
  
    }

    const unliketopVotedPrompt = async (id)=> {
        let uid = auth?.currentUser?.uid;

        let likesArray;
        for (let i = 0; i < highestVotedPrompts.length; i++) {
            if(highestVotedPrompts[i].id == id){
                likesArray = highestVotedPrompts[i].likes;
                break
            }
        }

        likesArray = likesArray.filter(item => item !== uid)
        await updateDoc(doc(db, "prompts", id), {
            likes : likesArray
        });
  
    }

    const likeTopVotedPrompt = async (id)=> {
        let uid = auth?.currentUser?.uid;

        let likesArray;
        for (let i = 0; i < highestVotedPrompts.length; i++) {
            if(highestVotedPrompts[i].id == id){
                likesArray = highestVotedPrompts[i].likes;
                break
            }
        }

        if(likesArray == undefined){
            likesArray = [];
        }

        if(!likesArray.includes(uid)){
            likesArray.push(uid);
            await updateDoc(doc(db, "prompts", id), {
                likes : likesArray
            });
        }
    }

    useEffect(() => {
        const q = query(collection(db, "prompts"), orderBy("timestamp"), limit(1000));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const dateOptions = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'};
            const otherPrompts = [];
            const myPrompts = [];
            const completedPrompts = [];
            let topPrompts = [];
            let topVotedPrompts = [];
            querySnapshot.forEach((doc) => {

                let uid = auth?.currentUser?.uid;

                const completed = doc.data().complete

                let likedByCurrentUser;
                if(doc.data().likes?.includes(uid)){
                    likedByCurrentUser = true;
                }else{
                    likedByCurrentUser = false;
                }

                let createdByCurrentUser
                if(doc.data().uid == uid){
                    createdByCurrentUser = true
                }else{
                    createdByCurrentUser = false
                }

                if(completed){
                    
                    completedPrompts.push(
                        {   
                            id: doc.id,
                            content: doc.data().content, 
                            uid: doc.data().uid, 
                            timestamp: doc.data().timestamp.toDate().toLocaleTimeString("en-US", dateOptions),
                            likes: doc.data().likes,
                            edited: doc.data().edited,
                            likedByCurrentUser: likedByCurrentUser,
                            createdByCurrentUser: createdByCurrentUser,
                            hideTopVotedPopup: doc.data().hideTopVotedPopup
                        }
                    );

                    return

                }else if(
                    doc.data().likes?.length >= 3 
                    && topVotedPrompts.length == 0){

                    topPrompts.push(
                        {   
                            id: doc.id,
                            content: doc.data().content, 
                            uid: doc.data().uid, 
                            timestamp: doc.data().timestamp.toDate().toLocaleTimeString("en-US", dateOptions),
                            likes: doc.data().likes,
                            edited: doc.data().edited,
                            likedByCurrentUser: likedByCurrentUser,
                            createdByCurrentUser: createdByCurrentUser,
                            hideTopVotedPopup: doc.data().hideTopVotedPopup
                        }
                    );

                    topVotedPrompts = [{
                        likeCount: doc.data().likes.length,
                        id: doc.id 
                    }]

                }else if(
                    doc.data().likes?.length >= 3 
                    && doc.data().likes?.length == topVotedPrompts[0].likeCount){

                    topPrompts.push(
                        {   
                            id: doc.id,
                            content: doc.data().content, 
                            uid: doc.data().uid, 
                            timestamp: doc.data().timestamp.toDate().toLocaleTimeString("en-US", dateOptions),
                            likes: doc.data().likes,
                            edited: doc.data().edited,
                            likedByCurrentUser: likedByCurrentUser,
                            createdByCurrentUser: createdByCurrentUser,
                            hideTopVotedPopup: doc.data().hideTopVotedPopup
                        }
                    );

                    topVotedPrompts.push({
                        likeCount: doc.data().likes?.length,
                        id: doc.id 
                    })
                
                }
                else if(topVotedPrompts.length > 0){

                    if(
                        doc.data().likes?.length >= 3 
                        && doc.data().likes?.length >= topVotedPrompts[0].likeCount ){

                        topPrompts = [{   
                                id: doc.id,
                                content: doc.data().content, 
                                uid: doc.data().uid, 
                                timestamp: doc.data().timestamp.toDate().toLocaleTimeString("en-US", dateOptions),
                                likes: doc.data().likes,
                                edited: doc.data().edited,
                                likedByCurrentUser: likedByCurrentUser,
                                createdByCurrentUser: createdByCurrentUser,
                                hideTopVotedPopup: doc.data().hideTopVotedPopup
                            }];
                        
                        topVotedPrompts = [{
                            likeCount: doc.data().likes?.length,
                            id: doc.id 
                        }]

                    }

                }  

                if(doc.data().uid == auth?.currentUser?.uid){
                    myPrompts.push(
                        {   
                            id: doc.id,
                            content: doc.data().content, 
                            uid: doc.data().uid, 
                            timestamp: doc.data().timestamp?.toDate()?.toLocaleTimeString("en-US", dateOptions),
                            likes: doc.data().likes,
                            edited: doc.data().edited,
                        }
                    );
                }else{

                    let uid = auth?.currentUser?.uid;
                    let duplicate = false;

                    let likedByCurrentUser;
                    if(doc.data().likes?.includes(uid)){
                        likedByCurrentUser = true;
                    }else{
                        likedByCurrentUser = false;
                    }

                    for (let i = 0; i < topVotedPrompts.length; i++) {
                        if(topVotedPrompts[i].id == doc.id){
                            duplicate = true;
                        }
                    }    

                    if(!duplicate){
                        otherPrompts.push(
                            {
                                id: doc.id,
                                content: doc.data().content, 
                                uid: doc.data().uid, 
                                timestamp: doc.data().timestamp.toDate().toLocaleTimeString("en-US", dateOptions),
                                likes: doc.data().likes,
                                likedByCurrentUser: likedByCurrentUser,
                                edited: doc.data().edited,
                            }
                        );
                    }
                }

            });
            setcompletePromptsList(completedPrompts);
            setHighestVotedPrompts(topPrompts);
            setOtherPromptsList(otherPrompts);
            setMyPromptsList(myPrompts);
        });
    }, [])

    return(
        <div>

            <div className="content">

                {highestVotedPrompts.length > 0 && highestVotedPrompts.map((prompt) => (
                    <div className="prompt topVotedPrompt mt-2" key={prompt.id}>
                        <div className="promptVoteCount">
                            <span>{prompt.timestamp}</span>
                            <div>
                                <div className="topVotedPromptBadge"><span className="material-symbols-outlined">trophy</span> Top Voted</div>
                                {prompt.edited &&
                                    <div className="topVotedPromptBadge"><span className="material-symbols-outlined">edit</span> Edited</div>
                                }
                            </div>
                        </div>
                            <div className="promptContent">
                                {prompt.content}
                            </div>
                        <div class="voteBar">
                            <span className='voteCounter'>
                                { prompt.likes?.length}
                                { prompt.likes == undefined && <span>0</span> } Vote
                                { prompt.likes?.length >= 2 && <span>s</span> }
                                { prompt.likes?.length == 0 && <span>s</span> }
                                { prompt.likes == undefined && <span>s</span> }
                            </span>
                            <div>
                                {!prompt.createdByCurrentUser &&
                                    <span>
                                        {!prompt.likedByCurrentUser &&
                                            <button className="secondaryButton rightFloat" onClick={() => likeTopVotedPrompt(prompt.id)}><span className="material-symbols-outlined">front_hand</span></button>
                                        }
                                        {prompt.likedByCurrentUser &&
                                            <button className="secondaryButtonSelected rightFloat" onClick={() => unliketopVotedPrompt(prompt.id)}><span className="material-symbols-outlined material-symbols-filled">front_hand</span></button>
                                        }
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                ))}

            </div>

            {otherPromptsList.length > 0 &&
                <div className="promptDivider">{otherPromptsList.length >= 2 && <span>{otherPromptsList.length}</span>} Other Thought{otherPromptsList.length >=2 && <span>s</span>}</div>
            }

            <div className="content">

            {otherPromptsList.length > 0 && otherPromptsList.map((prompt) => (
                    <div className="prompt" key={prompt.id}>
                        <div className="promptVoteCount">
                            <span>{prompt.timestamp}</span>
                            <div>
                                {prompt.edited &&
                                    <div className="topVotedPromptBadge"><span className="material-symbols-outlined">edit</span> Edited</div>
                                }
                            </div>
                        </div>
                        <div className="promptContent">
                            {prompt.content}
                        </div>
                        <div class="voteBar">
                            <span className='voteCounter'>
                                { prompt.likes?.length}
                                { prompt.likes == undefined && <span>0</span> } Vote
                                { prompt.likes?.length >= 2 && <span>s</span> }
                                { prompt.likes?.length == 0 && <span>s</span> }
                                { prompt.likes == undefined && <span>s</span> }
                            </span>
                            <div>
                            {!prompt.likedByCurrentUser &&
                                <button className="secondaryButton rightFloat" onClick={() => likePrompt(prompt.id)}><span className="material-symbols-outlined">front_hand</span></button>
                            }
                            {prompt.likedByCurrentUser &&
                                <button className="secondaryButtonSelected rightFloat" onClick={() => unlikePrompt(prompt.id)}><span className="material-symbols-outlined material-symbols-filled">front_hand</span></button>
                            }
                        </div>
                    </div>
                </div>
                ))}

            </div>

            {myPromptsList.length > 0 &&
                <div className="promptDivider">Your {myPromptsList.length >= 2 && <span>{myPromptsList.length}</span>} Thought{myPromptsList.length >=2 && <span>s</span>}</div>
            }

            <div className="content">

            {myPromptsList.length > 0 && myPromptsList.map((prompt) => (
                <div className="prompt" key={prompt.id}>
                    <div className="promptVoteCount">
                        <div>
                            <span>{prompt.timestamp}</span>
                        </div>
                        <div>
                        {prompt.edited &&
                                <div className="topVotedPromptBadge"><span className="material-symbols-outlined">edit</span> Edited</div>
                            }
                        </div>
                    </div>
                    <div className="promptContent">
                        {prompt.content}
                    </div>
                    <div class="voteBar">
                        <span className='voteCounter'>
                            { prompt.likes?.length}
                            { prompt.likes == undefined && <span>0</span> } Vote
                            { prompt.likes?.length >= 2 && <span>s</span> }
                            { prompt.likes?.length == 0 && <span>s</span> }
                            { prompt.likes == undefined && <span>s</span> }
                        </span>
                        <div className="editPromptButtons">
                            <button className="secondaryButtonSelected rightFloat" onClick={() => setItemToComplete(prompt)}><span className="material-symbols-outlined">done_all</span></button>
                            <button className="warningButtonIcon rightFloat" onClick={() => editPrompt(prompt.id)}><span className="material-symbols-outlined">edit</span></button>
                            <button className="dangerButtonIcon rightFloat" onClick={() => setItemToDelete(prompt)}><span className="material-symbols-outlined">delete</span></button>
                        </div>
                    </div>
                </div>

            ))}
            </div>

            {completePromptsList.length > 0 &&
                <div className="promptDivider">{completePromptsList.length >= 2 && <span>{completePromptsList.length}</span>} Complete Thought{completePromptsList.length >=2 && <span>s</span>}</div>
            }

            <div className="content">

            {completePromptsList.length > 0 && completePromptsList.map((prompt) => (
                    <div className="prompt" key={prompt.id}>
                        <div className="promptVoteCount">
                            <span>{prompt.timestamp}</span>
                            <div>
                                {prompt.edited &&
                                    <div className="topVotedPromptBadge"><span className="material-symbols-outlined">edit</span> Edited</div>
                                }
                            </div>
                        </div>
                        <div className="promptContent">
                            {prompt.content}
                        </div>
                    </div>
                ))}

            </div>

            {itemToDelete &&
                <div className="popupContainer">
                    <div className="popup">
                        <h3 className="popupTitle">Are you Sure?</h3>
                        <p>You are about to remove a Thought. Unlike real life, this cannot be undone</p>
                        <div className="prompt mb-2" key={itemToDelete.id}>
                            <div className="promptVoteCount">
                                { itemToDelete.timestamp }
                                <div>
                                {itemToDelete.edited &&
                                        <div className="topVotedPromptBadge"><span className="material-symbols-outlined">edit</span> Edited</div>
                                    }
                                </div>
                            </div>
                            <div className="promptContent">
                                {itemToDelete.content}
                            </div>
                            <div>
                                { itemToDelete.likes?.length }
                                { itemToDelete.likes == undefined && <span>0</span> } Vote
                                { itemToDelete.likes?.length >= 2 && <span>s</span> }
                                { itemToDelete.likes?.length == 0 && <span>s</span> }
                                { itemToDelete.likes == undefined && <span>s</span> }
                            </div>
                        </div>
                        <button className="dangerButton rightFloat pw-2" onClick={() => deletePrompt(itemToDelete.id)}>Remove</button>
                        <button className="neutralButton rightFloat pw-2 mr-1" onClick={() => setItemToDelete()}>Cancel</button>
                    </div>
                </div>
            }

            {itemToComplete &&
                <div className="popupContainer">
                    <div className="popup">
                        <h3 className="popupTitle">Are You Complete?</h3>
                        <p>If you are complete and would like to end the conversation, complete this thought. No one will be able to vote on it & you will lose the ability to edit and delete.</p>
                        <div className="prompt mb-2" key={itemToComplete.id}>
                            <div className="promptVoteCount">
                                { itemToComplete.timestamp }
                                <div>
                                { itemToComplete.edited &&
                                        <div className="topVotedPromptBadge"><span className="material-symbols-outlined">edit</span> Edited</div>
                                    }
                                </div>
                            </div>
                            <div className="promptContent">
                                {itemToComplete.content}
                            </div>
                            <div>
                                { itemToComplete.likes?.length }
                                { itemToComplete.likes == undefined && <span>0</span> } Vote
                                { itemToComplete.likes?.length >= 2 && <span>s</span> }
                                { itemToComplete.likes?.length == 0 && <span>s</span> }
                                { itemToComplete.likes == undefined && <span>s</span> }
                            </div>
                        </div>
                        <button className="primaryButton rightFloat pw-2" onClick={() => completeItem(itemToComplete.id)}>Complete</button>
                        <button className="neutralButton rightFloat pw-2 mr-1" onClick={() => setItemToComplete()}>Cancel</button>
                    </div>
                </div>
            }           
            
            {itemToEdit &&
                <div className="popupContainer">
                    <div className="popup"> 
                        <div className="content">
                            <div className="prompt mb-2">
                                <div className="promptVoteCount">
                                    <div>
                                        <span>{itemToEdit.timestamp}</span>
                                    </div>
                                    <div>
                                        {itemToEdit.edited &&
                                            <div className="topVotedPromptBadge"><span className="material-symbols-outlined">edit</span> Edited</div>
                                        }
                                    </div>
                                </div>
                                <textarea 
                                    ref={oldPromptContent}
                                    placeholder="Edit your thought here..."
                                    className="inputTextArea textareaNoBorder"
                                    autoFocus
                                    defaultValue={itemToEdit.content} 
                                    onChange={(e) => setEdditedPromtContent(e.target.value)}></textarea>
                                    <div class="voteBar">
                                        <span className='voteCounter'>
                                            { itemToEdit.likes?.length}
                                            { itemToEdit.likes == undefined && <span>0</span> } Vote
                                            { itemToEdit.likes?.length >= 2 && <span>s</span> }
                                            { itemToEdit.likes?.length == 0 && <span>s</span> }
                                            { itemToEdit.likes == undefined && <span>s</span> }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <button className="primaryButton rightFloat pw-2 mr-1" onClick={() => confirmEditPrompt(itemToEdit.id)}>Submit</button>
                            <button className="neutralButton rightFloat pw-2 mr-1" onClick={() => setItemToEdit()}>Cancel</button>
                        </div>
                    </div>
            }

            {highestVotedPrompts.length > 0 && highestVotedPrompts.map((prompt) => (
                <div key={prompt.id}>
                    {prompt.hideTopVotedPopup != true &&
                        <div>
                            {prompt.createdByCurrentUser &&
                                <div className="popupContainer">
                                    <div className="popup">
                                        <h3 className="popupTitle">Your Thought is Top Voted!</h3>
                                        <p>Perhaps you may want to speak up and share with the team?</p>
                                        <div className="content">
                                            <div className="prompt topVotedPrompt mb-2" key={prompt.id}>
                                                    <div className="promptVoteCount">
                                                    <div>
                                                        <span>{prompt.timestamp}</span>
                                                    </div>
                                                    <div>
                                                        <div className="topVotedPromptBadge"><span className="material-symbols-outlined">trophy</span> Top Voted</div>
                                                        {prompt.edited &&
                                                            <div className="topVotedPromptBadge"><span className="material-symbols-outlined">edit</span> Edited</div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="promptContent">
                                                    {prompt.content}
                                                </div>
                                                <div class="voteBar">
                                                    <span className='voteCounter'>
                                                        { prompt.likes?.length}
                                                        { prompt.likes == undefined && <span>0</span> } Vote
                                                        { prompt.likes?.length >= 2 && <span>s</span> }
                                                        { prompt.likes?.length == 0 && <span>s</span> }
                                                        { prompt.likes == undefined && <span>s</span> }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="primaryButton rightFloat pw-2 mr-1" onClick={() => closeTopVotedPopup(prompt.id)}>Got It</button>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            ))}         

        </div>
    );
}