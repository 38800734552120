import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA45vRHcCJO4LGYv7JkMuPnqCP4FhqwdX4",
  authDomain: "spark-thoughts.firebaseapp.com",
  projectId: "spark-thoughts",
  storageBucket: "spark-thoughts.appspot.com",
  messagingSenderId: "60385595677",
  appId: "1:60385595677:web:f0ebd14e2f353df94c11f4",
  measurementId: "G-NHT14873F3"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);