import { db } from '../config/firebase'
import { onSnapshot, doc } from "firebase/firestore"

export const useStore = (() => {
    let storeInstance;
    const createStoreInstance = () => {
        //...
        let store = '';
        const getStore = () => {
            return store;
        }
        const setStore = (newStore) => {
            store = newStore;
            return store;
        }

        return [getStore. setStore]
    }

    return () => {
        if(!storeInstance) {
            storeInstance = createStoreInstance()
        }
        return storeInstance;
    }
})()

// export const store = () => {
//     let storeObj = {};
//     onSnapshot(doc(db, "globals", "varibables"), (doc) => {
//         storeObj = doc.data().headerTextTest;
//     });
//     return storeObj;
// }