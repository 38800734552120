import { useState, useEffect, useRef } from "react";
import { db, auth } from '../config/firebase'
import { serverTimestamp, addDoc, collection, query, where, onSnapshot, doc } from "firebase/firestore"

export const Navbar = () => {

    const [prompt, setPrompt] = useState("");
    const [disableSubmitButton, setDisableSubmitButton] = useState("");
    const [headerText, setHeaderText] = useState("");
    const [promptCount, setPromptCount] = useState([]);
    const newPromptContent = useRef(null); 
    

    useEffect(() => {
        if(prompt.length > 0){
            setDisableSubmitButton(false)
        }else if (prompt.length == 0){
            setDisableSubmitButton(true)
        }
    }, [prompt])

    useEffect(() => {
        const q = query(collection(db, "prompts"), where("BowlId", "==", 0));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            setPromptCount(querySnapshot.docs.length)
        });
    }, [])

    useEffect(() => {
        onSnapshot(doc(db, "globals", "varibables"), (doc) => {
            setHeaderText(doc.data().headerText);
        });
    }, [])

    async function submitNewPrompt() {
        newPromptContent.current.value = "";

        await addDoc(collection(db, "prompts"), {
            BowlId: 0,
            uid: auth?.currentUser?.uid,
            timestamp: serverTimestamp(),
            content: prompt
        });

        setDisableSubmitButton(true)
    }
    
    return(
        <div className="navbar">
            <h1 className="promptCounter">
                {promptCount} Thought{promptCount >= 2 && <span>s</span> }
                {promptCount == 0 && <span>s</span> }
            </h1>
            <h3 className="headerText">{headerText}</h3>

            <div class="writeMessage">
            <input 
                ref={newPromptContent}
                autoFocus
                // onKeyDown={onEnterPress}
                placeholder="Share your thought here..." 
                className="inputTextArea" 
                onChange={(e) => setPrompt(e.target.value)}></input>

                <button 
                    className="primaryButton" 
                    id="submitPrompt" 
                    onClick={submitNewPrompt} 
                    disabled={disableSubmitButton}>
                    <span 
                        className="material-symbols-outlined">
                        Send
                    </span> 
                </button>
            </div>
      </div>
    );
}